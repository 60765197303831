@import url('https://fonts.googleapis.com/css2?family=Fredoka&display=swap');

body {
	margin: 0;
	font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}
p {
	display: block;
	margin-block-start: 0px;
	margin-block-end: 0px;
	margin-inline-start: 0px;
	margin-inline-end: 0px;
}
h1 {
	display: block;
	margin-block-start: 0px;
	margin-block-end: 0px;
	margin-inline-start: 0px;
	margin-inline-end: 0px;
}
h2 {
	display: block;
	margin-block-start: 0px;
	margin-block-end: 0px;
	margin-inline-start: 0px;
	margin-inline-end: 0px;
}
h3 {
	display: block;
	margin-block-start: 0px;
	margin-block-end: 0px;
	margin-inline-start: 0px;
	margin-inline-end: 0px;
}
h4 {
	display: block;
	margin-block-start: 0px;
	margin-block-end: 0px;
	margin-inline-start: 0px;
	margin-inline-end: 0px;
}
h5 {
	display: block;
	margin-block-start: 0px;
	margin-block-end: 0px;
	margin-inline-start: 0px;
	margin-inline-end: 0px;
}
h6 {
	display: block;
	margin-block-start: 0px;
	margin-block-end: 0px;
	margin-inline-start: 0px;
	margin-inline-end: 0px;
}
code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

a.powered-by-firepad {
	display: none;
}

.cm-focused {
	outline: none !important;
}

code {
	background-color: #eee;
	border-radius: 4px;
}
.MuiIconButton-root {
	border: 1px solid #eee !important;
}

.MuiInputLabel-root {
	z-index: 0 !important;
}
@media print {
	::-webkit-scrollbar {
		display: none;
	}

	.candidateContent {
		height: 950px;
		overflow: visible;
	}

	.candidateOptions {
		display: none !important;
	}
}
.ehXcGc {
	color: #3d74c3 !important;
	background: #d5ebff !important;
	text-transform: none !important;
}
.MuiDialog-paper {
	border-radius: 15px !important;
}

/* *::-webkit-scrollbar-track {
	box-shadow: inset 0 0 6px rgba(23, 118, 210, 0.3);
	border-radius: 10px;
	background-color: #F5F5F5;
}

*::-webkit-scrollbar {
	width: 4px;
	height: 4px;
	background-color: #F5F5F5;
}

*::-webkit-scrollbar-thumb {
	border-radius: 10px;
	box-shadow: inset 0 0 6px rgba(23, 118, 210, .3);
	background-color: rgb(23, 118, 210);
} */

.rbc-events-container {
	display: grid;
	grid-template-rows: repeat(24, 40px);
}

.rbc-day-slot .rbc-event {
	position: static;
}
.rbc-toolbar button:active,
.rbc-toolbar button.rbc-active {
	background-color: #fff !important;
	color: #1776d2 !important;
	border-color: #1776d2 !important;
}
.rbc-toolbar button:active,
.rbc-toolbar button.rbc-active:hover {
	background-color: #fff !important;
	color: #1776d2 !important;
	border-color: #1776d2 !important;
	box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}

.rbc-toolbar button {
	color: #fff !important;
	background: #1776d2 !important;
}

.rbc-toolbar button:hover {
	color: #fff !important;
	background-color: #1565c0 !important;
	box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.allotment-module_splitView__1rmCV {
	overflow: auto !important;
}
.MuiDialog-container {
	height: auto !important;
	align-items: baseline !important;
	padding-top: 20px !important;
}
