@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Leckerli+One&display=swap');

:root {
	--gray-800: #111111;
	--gray-500: #9ca3af;
	--gray-300: #d1d5db;
	--focus-color: #68cef8;
	--violet: #4338ca;
	--z-index-1: 1;
	--z-index-2: 2;
}

.editor-wrapper {
	width: 100%;
	margin: auto;
	max-width: 45rem;
}

.TiptapEditor::-webkit-scrollbar-track {
	box-shadow: inset 0 0 6px rgba(23, 118, 210, 0.3);
	border-radius: 10px;
	background-color: #F5F5F5;
}

.TiptapEditor::-webkit-scrollbar {
	width: 5px;
	background-color: #F5F5F5;
}

.TiptapEditor::-webkit-scrollbar-thumb {
	border-radius: 10px;
	box-shadow: inset 0 0 6px rgba(23, 118, 210, .3);
	background-color: rgb(23, 118, 210);
}

.rich-text-options-wrapper {
	display: flex;
	flex-wrap: wrap;
	position: sticky;
	top: 0;
	background-color: white;
	z-index: var(--z-index-1);
	padding: 0.5rem 0 1rem;
}

.rich-text-options-wrapper .MuiButtonGroup-grouped:last-of-type {
	border-top-right-radius: 12px;
	border-bottom-right-radius: 12px;
}

.rich-text-options-wrapper .MuiButtonGroup-grouped:first-of-type {
	border-top-left-radius: 12px;
	border-bottom-left-radius: 12px;
}

.ProseMirror {
	font-family: 'Poppins', sans-serif;
	padding: 0 0 0.5rem;
}

.ProseMirror ul, ol {
	margin: 0;
}

.ProseMirror img {
	height: auto;
	max-width: 100%;
}

.ProseMirror-selectednode {
	outline: 3px solid #68cef8;
}

.ProseMirror table {
	border-collapse: collapse;
	margin: 0;
	overflow: hidden;
	table-layout: fixed;
	width: -webkit-fill-available;
}

.selectedCell:after {
	background: rgba(200, 200, 255, 0.4);
	content: "";
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
	pointer-events: none;
	position: absolute;
	z-index: 2;
}

.column-resize-handle {
	background-color: #1776D2;
	bottom: -2px;
	position: absolute;
	right: -2px;
	pointer-events: none;
	top: 0;
	width: 4px;
}

.ProseMirror table td {
	border: 2px solid #ced4da;
	box-sizing: border-box;
	min-width: 1em;
	padding: 1px 5px;
	position: relative;
	vertical-align: top;
	text-align: left;
}

.ProseMirror table p {
	margin: 0 0;
}

.ProseMirror th {
	border: 2px solid #ced4da;
	box-sizing: border-box;
	min-width: 1em;
	padding: 1px 5px;
	position: relative;
	vertical-align: top;
	text-align: left;
	background-color: #f1f3f5;
	font-weight: bold;
}

.ProseMirror:focus {
	outline: none;
}

.ProseMirror img {
	max-width: 100%;
	height: 20rem;
}

.ProseMirror .ProseMirror-selectednode {
	outline: 2px solid var(--focus-color);
}

a {
	color: var(--violet);
	cursor: pointer;
}

.ProseMirror p.is-editor-empty:first-child::before {
	content: 'Write your thoughts...';
	float: left;
	color: #ced4da;
	pointer-events: none;
	height: 0;
}

b {
	font-weight: 600;
}

ul[data-type="taskList"] {
	list-style: none;
	padding: 0;

}

ul[data-type="taskList"] li {
	display: flex;
}

ul[data-type="taskList"] li p {
	margin: 0
}

.resize-cursor {
	cursor: ew-resize;
	cursor: col-resize;
}

.ProseMirror code {
	background-color: rgba(97, 97, 97, 0.1);
	border-radius: 0.25em;
	color: rgb(97, 97, 97);
	font-size: 0.9rem;
	padding: 0.25em;
}

.ProseMirror pre {
	background: #0d0d0d;
	border-radius: 0.5rem;
	color: #fff;
	font-family: "JetBrainsMono", monospace;
	padding: 0.75rem 1rem;
}

.ProseMirror pre code {
	background: none;
	color: inherit;
	font-size: 0.8rem;
	padding: 0;
}

.hljs-comment,
.hljs-quote {
	color: #616161;
}

.hljs-variable,
.hljs-template-variable,
.hljs-attribute,
.hljs-tag,
.hljs-name,
.hljs-regexp,
.hljs-link,
.hljs-name,
.hljs-selector-id,
.hljs-selector-class {
	color: #f98181;
}

.hljs-number,
.hljs-meta,
.hljs-built_in,
.hljs-builtin-name,
.hljs-literal,
.hljs-type,
.hljs-params {
	color: #fbbc88;
}

.hljs-string,
.hljs-symbol,
.hljs-bullet {
	color: #b9f18d;
}

.hljs-title,
.hljs-section {
	color: #faf594;
}

.hljs-keyword,
.hljs-selector-tag {
	color: #70cff8;
}

.hljs-emphasis {
	font-style: italic;
}

.hljs-strong {
	font-weight: 700;
}

.ProseMirror blockquote {
	border-left: 3px solid rgba(13, 13, 13, 0.1);
	padding-left: 0.5rem;
}

.TiptapEditor button {
	padding: 0
}

.ProseMirror-selectednode hr {
	border-top: 1px solid #68cef8;
}