.firepad-userlist {
    height: 100%;
    min-width: 175px;
  
    background: #ebebeb; /* Old browsers */
    background: -moz-linear-gradient(left, #ebebeb 0%, #eaeaea 93%, #d9d9d9 100%); /* FF3.6+ */
    background: -webkit-gradient(linear, left top, right top, color-stop(0%,#ebebeb), color-stop(93%,#eaeaea), color-stop(100%,#d9d9d9)); /* Chrome,Safari4+ */
    background: -webkit-linear-gradient(left, #ebebeb 0%,#eaeaea 93%,#d9d9d9 100%); /* Chrome10+,Safari5.1+ */
    background: -o-linear-gradient(left, #ebebeb 0%,#eaeaea 93%,#d9d9d9 100%); /* Opera 11.10+ */
    background: -ms-linear-gradient(left, #ebebeb 0%,#eaeaea 93%,#d9d9d9 100%); /* IE10+ */
    background: linear-gradient(to right, #ebebeb 0%,#eaeaea 93%,#d9d9d9 100%); /* W3C */
    color: #404040;
  }
  
  .firepad-userlist {
    text-align: left;
    font-family: 'Helvetica Neue', sans-serif;
    line-height: normal;
  }
  
  .firepad-userlist-heading {
    margin: 0px 5px 0;
    padding-top: 20px !important;
    font-size: 12px;
    font-weight: bold;
    border-bottom: 2px solid #c9c9c9;
  }
  
  .firepad-userlist-users {
    position: absolute;
    left: 5px;
    right: 15px;
    top: 38px;
    bottom: 10px;
    overflow-y: auto;
    overflow-x: hidden;
  }
  
  .firepad-userlist-user {
    position: relative;
    margin: 3px 0;
    height: 30px;
    border-bottom: 1px solid #c9c9c9;
    padding-bottom: 5px;
  }
  
  .firepad-userlist-color-indicator {
    display: inline-block;
    width: 25px;
    height: 30px;
    border: 1px solid #ccc;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px;
  }
  
  input.firepad-userlist-name-input {
    position: absolute;
    left: 31px;
    top: 0;
    width: 105px;
    height: 20px;
    border: 0;
    border-bottom: 1px solid #d6d6d6;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    border-radius: 0;
    font-size: 14px;
    line-height: 14px;
    padding: 1px;
  }
  
  .firepad-userlist-name-hint {
    position: absolute;
    left: 31px;
    top: 23px;
    width: 300px;
    font-size: 9px;
    line-height: 11px;
  }
  
  .firepad-userlist-name {
    position: absolute;
    top: 2px;
    left: 31px;
    width: 95px;
    font-size: 13px;
  }