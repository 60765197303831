.Board {
  width: 100%;
  height: 500px;
  position: relative;
}

.AddCard {
  position: fixed;
  cursor: pointer;
  padding: 10px 20px;
  border-radius: 10px;
  font-weight: bold;
  z-index: 1000;
  background: white;
  margin: 12px;
}

.Card {
  border: 1px solid #bdbdbd;
  padding: 20px;
  padding-bottom: 30px;
  position: absolute;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  background-color: white;
  border-radius: 15px;
  transition: 0.07s;
  opacity: 0.99;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.DeleteBtn {
  position: absolute;
  top: 8px;
  right: 8px;
  opacity: 0.5;
  border: none;
  background: transparent;
  cursor: pointer;
}

.EditableText {
  min-width: 80px;
  height: 100%;
  border: none;
  font-size: 16px !important;
  line-height: 1rem;
  font-family: "Fredoka";
  background: transparent;
  overflow: auto;
}
.EditableTitle:focus-visible{
  outline-offset: unset;
}
.EditableTitle {
  border: none;
  font-family: "Fredoka";
  background: transparent;
  overflow: hidden;
  font-weight: 600;
  font-size: 1rem;
  resize: none;
}

.Title{
  margin:0;
  display: flex;
  justify-content: center;
  padding-right: 12px;
  font-size: 1rem;
  font-weight: 600;
  font-family: "Fredoka";
  cursor: text;
}
.Content {
  height: 100%;
  width: 100%;
  padding-right: 12px;
  font-size: 16px !important;
  line-height: 1rem !important;
  font-family: "Fredoka" !important;
  word-wrap: break-word;
  white-space: pre-wrap;
  cursor: text;
  overflow: auto;
}

textarea:focus {
  outline: 0;
}

.ColorSelector {
  position: absolute;
  top: 30px;
  right: 9px;
}
.ColorCircle {
  width: 13px;
  height: 13px;
  margin: 2px;
  padding: 2px;
  border: solid 1px #dedede;
  border-radius: 50%;
  cursor: pointer;
}

.date{
  position: absolute;
  width: 100%;
  display: flex;
  justify-content: center;
  left:0px;
  bottom: 5px;
  opacity: 0.7;
  border: none;
  font-size: 10px;
  background: transparent;
}

.addButton {
  position: absolute;
  left:5px;
  top: 5px;
  opacity: 0.7;
}
textarea {
  resize: none;
}
input {
  outline: none !important;
}