.App {
    font-family: sans-serif;
    text-align: center;
}

.button-wrapper button {
    z-index: 1;
    height: 40px;
    max-width: 200px;
    margin: 10px;
    padding: 5px;
}

.excalidraw .App-menu_top .buttonList {
    display: flex;
}


:root[dir="ltr"] .excalidraw .layer-ui__wrapper .zen-mode-transition.App-menu_bottom--transition-left {
    transform: none
}

.excalidraw .panelColumn {
    text-align: left;
}

.export-wrapper {
    display: flex;
    flex-direction: column;
    margin: 50px;
    z-index: 1000;
}

.excalidraw {
    --color-primary: #faa2c1;
    --color-primary-darker: #f783ac;
    --color-primary-darkest: #e64980;
    --color-primary-light: #fcc2d7;
    --color-primary-chubb: #faa2c1;
}